import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

import '@splidejs/splide/css/core'
import Splide from '@splidejs/splide'

window.Alpine = Alpine
window.Splide = Splide

Alpine.plugin(collapse)
Alpine.start()
